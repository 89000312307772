<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>Add New "How We Recruit" page</v-card-title>
          <v-card-text>
            <v-col cols="6">
              <v-autocomplete v-model="page.skill_id" :items="skillList" chips item-value="id" item-text="title" label="Select skill" clearable></v-autocomplete>
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="page.title" label="Title" persistent-hint hint="For example: The Best iOS Developers"></v-text-field>
            </v-col>
            <v-divider></v-divider>
            <v-col>
              <h2>Section 1</h2>
              <v-text-field v-model="page.s1title" label="Title"></v-text-field>
              <v-textarea v-model="page.s1description" label="Description"></v-textarea>
              <v-file-input v-model="page.s1file" label="Image"></v-file-input>
            </v-col>
            <v-divider></v-divider>
            <v-col>
              <h2>Section 2</h2>
              <v-text-field v-model="page.s2title" label="Title"></v-text-field>
              <v-textarea v-model="page.s2description" label="Description"></v-textarea>
              <v-file-input v-model="page.s2file" label="Image"></v-file-input>
            </v-col>
            <v-divider></v-divider>
            <v-col>
              <h2>Section 3</h2>
              <v-text-field v-model="page.s3title" label="Title"></v-text-field>
              <v-textarea v-model="page.s3description" label="Description"></v-textarea>
              <v-file-input v-model="page.s3file" label="Image"></v-file-input>
            </v-col>
            <v-col cols="2">
              <v-btn @click="postData" :loading="loading" color="primary">Submit</v-btn>
            </v-col>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  name: "HowWeRecruit",

  mounted() {
    this.getSkills()
  },

  data() {
    return {
      page: {
        title: "",
        s1title: "",
        s1description: "",
        s1file: null,
        s2title: "",
        s2description: "",
        s2file: null,
        s3title: "",
        s3description: "",
        s3file: null,
        skill_id: null
      },
      loading: false,
      skillList: [],
    }
  },

  methods: {
    postData() {
      let self = this

      self.loading = true

      const formData = new FormData();

      Object.keys(self.page).forEach(key => formData.append(key, self.page[key]));

      axios.get(`${this.$store.state.apiUrl}/sanctum/csrf-cookie`).then(response => {
        console.log(response)
        axios.post(`${this.$store.state.apiUrl}/api/how-we-recruit`, formData,{ headers: {
            'content-type': 'multipart/form-data'
          }})
            .then(function () {
              self.$store.commit("displaySnackbar", {
                message: "New 'How We Recruit' Page Added",
                color: "info"
              })
              self.loading = false
            })
            .catch(function (response) {
              alert(response.response.data.errors)
            })
      });
    },

    getSkills() {
      let self = this

      axios.get(`${this.$store.state.apiUrl}/api/skills`, this.$store.state.headers)
          .then(function (response) {
            self.skillList = response.data
          });
    },
  }
}
</script>

<style scoped>

</style>
